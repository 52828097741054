import { useInject } from '@actr/di';
import { useNavigation } from '@actr/hooks';
import { LOGIN_STATUS } from '@actr/specs';
import { AuthStore } from '@actr/stores';
import { AccountRoutes, SiteLoader } from '@actr/widgets';
import { observer } from 'mobx-react-lite';
import { LoginPageLocationState } from 'Pages/ak-redesign/auth/login/login';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ProjectUtils from 'Root/utils/project';

const withAuth = (
  WrappedComponent: React.FC<any>
): React.FC<any> => observer((props: any) => {
  const auth = useInject(AuthStore);

  const history = useHistory();
  const { createRouteUrl, navigate } = useNavigation();

  if (auth.status === LOGIN_STATUS.NOTLOGGEDIN) {
    if (ProjectUtils.isWebview()) { // нужно, что бы приложение среагировало на изменение роута
      navigate(AccountRoutes.LOGIN, {
        useRouter: false
      });
    } else {
      history.push(createRouteUrl(AccountRoutes.LOGIN, { }), ({
        redirectAfterLoginTo: history.location,
      }) as LoginPageLocationState);
    }

    return null;
  }

  if (auth.status === LOGIN_STATUS.LOGGEDIN) {
    return (
      <WrappedComponent {...props} />
    );
  }

  return (
    <SiteLoader />
  );
});

export default withAuth;
